<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem label="标题" prop="title">
            <Input :maxlength="45" show-word-limit v-model="form.title"/>
          </FormItem>
         
          <FormItem label="有效天数" prop="valid_days">
            <InputNumber :min="0" class="input-number-long" v-model="form.valid_days"/>天
            <p class="text-sm text-grey">卡片激活后的有效时间</p>
          </FormItem>
          <FormItem label="市场价" prop="market_price">
            <InputNumber :min="0" class="input-number-long" v-model="form.market_price"/>元
          </FormItem>
          <FormItem label="售价" prop="price">
            <InputNumber :min="0" class="input-number-long" v-model="form.price"/>元
          </FormItem>
          <FormItem label="分享赚优惠价" prop="share_price">
            <InputNumber :min="0" class="input-number-long" v-model="form.share_price"/>元
          </FormItem>
          <FormItem label="分享佣金" prop="share_commission">
            <InputNumber :min="0" class="input-number-long" v-model="form.share_commission"/>元
          </FormItem>
          <FormItem label="链接有效期" prop="share_valid_days">
            <InputNumber :min="0" class="input-number-long" v-model="form.share_valid_days"/>天
            <p class="text-sm text-grey">链接分享后的有效时间</p>
          </FormItem>
          <FormItem label="说明" prop="description">
            <Input :maxlength="85" show-word-limit v-model="form.description"/>
          </FormItem>
          <FormItem label="权重排序">
            <InputNumber
              :min="-32768"
              :max="32767"
              class="input-number-long"
              v-model="form.weight"
            />
            <p class="text-sm text-grey">从大到小排序</p>
          </FormItem>

          <FormItem label="是否可见">
            <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
              <span slot="open">是</span>
              <span slot="close">否</span>
            </i-switch>
          </FormItem>
        </Col>
      </Row>

      <Divider/>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem>
            <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
            <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      form: {
        title: '',
        valid_days: 0,
        market_price: 0,
        price: 0,
        share_price:0,
        share_commission:0,
        share_valid_days:0,
        description: '',
        weight:0,
        status:0
      },
      validate: {
        member_card_channel_id: [
          {
            required: true,
            type: "title",
            min:0,
            message: "请输入标题",
            trigger: "blur"
          }
        ],
       
        valid_days: [
          {
            required: true,
            type: "number",
            message: "请输入有效天数",
            trigger: "blur"
          }
        ],
        market_price: [
          {
            required: true,
            type: "number",
            message: "请输入市场价",
            trigger: "blur"
          }
        ],
        price: [
          {
            required: true,
            type: "number",
            message: "请输入售价",
            trigger: "blur"
          }
        ],
        share_valid_days: [
          {
            required: true,
            type: "number",
            message: "请输入链接分享后有效天数",
            trigger: "blur"
          }
        ],
        share_price: [
          {
            required: true,
            type: "number",
            message: "请输入分享赚优惠价",
            trigger: "blur"
          }
        ],
        share_commission: [
          {
            required: true,
            type: "number",
            message: "请输入分享佣金",
            trigger: "blur"
          }
        ],
      },
      channels: []
    };
  },
  created() {
    this.id && this.loadData();
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = "/memberCardOnline/" + (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/memberCardOnline/detail/id/" + this.id).then(res => {
        this.form = res;
      });
    },
  }
};
</script>